import ApiService from './api.service';

export const PhaseService = {
  query(params) {
    return ApiService.query('phases', {
      params,
    });
  },
  get(slug) {
    return ApiService.get('phases', slug);
  },
  create(params) {
    return ApiService.post('phases', { phase: params });
  },
  update(slug, params) {
    return ApiService.update('phases', slug, { phase: params });
  },
  destroy(slug) {
    return ApiService.delete(`phases/${slug}`);
  },
};

export default PhaseService;
